<template>
  <div class="bgblack">
    <RouterView/>
  </div>
</template>

<script setup>

</script>

<style>
body{
  background-color:#1a1a1a;
}
*{
  font-family: PingFangSC-Ultralight, sans-serif;
}
.bgblack{
  background-color:#1a1a1a !important;
  height: 100vh;
  width: 100vw;
}
input:focus, textarea:focus button:focus{
  outline: none;
}
</style>
