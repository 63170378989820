import {createRouter,createWebHashHistory} from 'vue-router'
const routes=[
  {path:'/',component:()=>import('../components/Home')},
  {path:'/download',component: ()=>import("../components/Download")},
]
export default  createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})
